<div class="app">
    
        <section class="hero" id="accueil">
            <svg id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 150" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(255, 255, 255, 1)" offset="0%"></stop><stop stop-color="rgba(255, 255, 255, 1)" offset="100%"></stop></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,105L18.5,100C36.9,95,74,85,111,90C147.7,95,185,115,222,107.5C258.5,100,295,65,332,62.5C369.2,60,406,90,443,102.5C480,115,517,110,554,100C590.8,90,628,75,665,62.5C701.5,50,738,40,775,50C812.3,60,849,90,886,107.5C923.1,125,960,130,997,130C1033.8,130,1071,125,1108,117.5C1144.6,110,1182,100,1218,100C1255.4,100,1292,110,1329,112.5C1366.2,115,1403,110,1440,102.5C1476.9,95,1514,85,1551,90C1587.7,95,1625,115,1662,120C1698.5,125,1735,115,1772,107.5C1809.2,100,1846,95,1883,87.5C1920,80,1957,70,1994,57.5C2030.8,45,2068,30,2105,42.5C2141.5,55,2178,95,2215,92.5C2252.3,90,2289,45,2326,42.5C2363.1,40,2400,80,2437,95C2473.8,110,2511,100,2548,92.5C2584.6,85,2622,80,2640,77.5L2658.5,75L2658.5,150L2640,150C2621.5,150,2585,150,2548,150C2510.8,150,2474,150,2437,150C2400,150,2363,150,2326,150C2289.2,150,2252,150,2215,150C2178.5,150,2142,150,2105,150C2067.7,150,2031,150,1994,150C1956.9,150,1920,150,1883,150C1846.2,150,1809,150,1772,150C1735.4,150,1698,150,1662,150C1624.6,150,1588,150,1551,150C1513.8,150,1477,150,1440,150C1403.1,150,1366,150,1329,150C1292.3,150,1255,150,1218,150C1181.5,150,1145,150,1108,150C1070.8,150,1034,150,997,150C960,150,923,150,886,150C849.2,150,812,150,775,150C738.5,150,702,150,665,150C627.7,150,591,150,554,150C516.9,150,480,150,443,150C406.2,150,369,150,332,150C295.4,150,258,150,222,150C184.6,150,148,150,111,150C73.8,150,37,150,18,150L0,150Z"></path></svg>
            <div class="content my-container">

                <div class="text-network-content">

                    <div class="text-network">
                        
                            <span class="greetings">Salut, je m'appelle </span>
                            <span class="name"> 
                                <span class="prenom">Samuel</span> 
                                <span class="nom">Mandeng </span>
                            </span>
                            <div class="work"> 
                                <span>je suis un </span>
                                <span id="typewriter"></span>
                            </div>

                            
                            <span class="i-do">
                                Je design et développe des sites et applications web (site vitrine, site e-commerce) sur mesure.
                            </span>
        
                            <button>

                                <a  href="https://drive.google.com/file/d/1270LhSlQntvSgBWDzf0rL0qNXKpya-lb/view?usp=sharing" 
                                    aria-label="Cv" 
                                    rel="noopener" 
                                    target="_blank">
                                    Téléchargez cv
                                </a>
                                <fa-icon [icon]=faDownload></fa-icon>

                            </button>
                               
                            <div class="networks">
                                    
                                <i class="pi pi-linkedin linkedin" (click)="openNetwork('linkedin')"></i>
                                <i class="pi pi-github github" (click)="openNetwork('github')"></i>
                                <i class="pi pi-instagram instagram" (click)="openNetwork('instagram')"></i>
              
                            </div>


                    </div>  

                    <img class="circle-one" src="../../../assets/img/circle-one.png" alt="just a design circle darkblue yellow and red">
                    <img class="cross" src="../../../assets/img/cross.png" alt="just a design cross pink olive green skyblue ">
                     
                </div>
                <img src="../../../assets/img/moi-hero-banner.png" alt="Image of Samuel Mandeng" class="hero-image">
            </div>
        </section>
        
        <section class="about" id="a-propos">

            <div class="content my-container">

                <span class="title">À Propos</span>
                <div class="text-image">

                    <img src="../../../assets/img/desk.jpeg" alt="illustration of a digital solutions">

                    <div class="text">
                        <span>
                            Je suis un développeur fullstack js web basé à Rennes. Je conçois et développe des sites et applications web en m'outillant de ma créativité sans jamais faire de compromis sur la qualité.
                        </span>

                        <span>Que vous soyez un particulier, une startup ou une entreprise établie cherchant à franchir l'étape numérique, je travaillerai avec vous pour créer des solutions digitales innovantes dont vous avez besoin pour développer votre activité .</span>
         
                        <span>
                            Je suis autonôme, travailleur et passionné. J'aime me frotter à de nouveaux défis. N'hésitez donc pas à me contacter afin qu'on atteigne ensemble vos objectifs. 
                        </span>
                    </div>
        
                </div>
        
                <div class="profil">
        
                    <div class="profil-bloc">
        
                        <div class="profil-nav">
                            <span class="profil-header active-header" (click) = "activeContent(0)">Compétences</span>
                            <span class="profil-header" (click) = "activeContent(1)">Formation</span>
                        </div>
        
                            <div class="profil-content">
            
                                <div class="profil-info technos">
                                    <div class="profil-info-conteneur">
                                        <div class="skills">
                                            <span class="skills-title">Front-End</span>
                                            <div class="images-logo">
                                                
                                                <div>
                                                    <img src="../../../assets/img/logos/angular_logo.png" alt="angular logo">
                                                    <span>Angular</span>
                                                </div>
                
                                                <div>
                                                    <img src="../../../assets/img/logos/bootstrap_logo.png" alt="bootstrap logo">
                                                    <span>Bootstrap</span>
                                                </div>
                
                                                <div>
                                                    <img src="../../../assets/img/logos/scss_logo.png" alt="Scss logo">
                                                    <span>Saas</span>
                                                </div>
                
                                                <div>
                                                    <img src="../../../assets/img/logos/primeng_logo.png" alt="Scss logo">
                                                    <span>Prime NG</span>
                                                </div>
                                            </div>
                                        </div>
                    
                                        <div class="skills">
                                            <span class="skills-title">Back-End</span>
                                            <div class="images-logo">
                                                <div>
                                                    <img src="../../../assets/img/logos/node_logo.png" alt="Node js logo">
                                                    <span>Node js</span>
                                                </div>
                
                                                <div>
                                                    <img src="../../../assets/img/logos/express_logo.png" alt="Express js logo">
                                                    <span>Express js</span>
                                                </div>
                
                                                <div>
                                                    <img src="../../../assets/img/logos/nodemailer_logo.png" alt="Nodemailer logo">
                                                    <span>Nodemailer</span>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="skills">
                                            <span class="skills-title">Base de donées</span>
                                            <div class="images-logo">
                                                <div>
                                                    <img src="../../../assets/img/logos/mongodb_logo.png" alt="MongoDB logo">
                                                    <span>MongoDB</span>
                                                </div>
                
                                                <div>
                                                    <img src="../../../assets/img/logos/postgres_logo.png" alt="Postgresql js logo">
                                                    <span>PostgreSQL</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="skills">
                                            <span class="skills-title">Design</span>
                                            <div class="images-logo">
                                                <div>
                                                    <img src="../../../assets/img/logos/adobe_logo.png" alt="Adobe XD logo">
                                                    <span>Adobe XD</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>               
            
                                <div class="formation profil-info ">
                                    <img src="../../../assets/img/hat.png" alt="chapeau de diplômant">
                                    <div class="formation-conteneur">
                                        <div class="formation-element">
                                            <span><fa-icon [icon]=faCalendarAlt></fa-icon><span>2016 - 2019</span></span>
                                            <span class="formation-title">Cycle d'Ingénierie Informatique (3iL)</span>
                                            <span class="formation-description">Conception et développement de système d’information</span>
                                        </div>
                
                                        <div class="formation-element">
                                            <span><fa-icon [icon]=faCalendarAlt></fa-icon><span>2014 - 2016</span></span>
                                            <span class="formation-title">Classes préparatoires (Prépa 3iL)</span>
                                            <span class="formation-description">Informatique, mathématiques, physiques</span>
                                        </div>
                
                                        <div class="formation-element">
                                            <span class="formation-title">Langues</span>
                                            <span class="langue">Français ( langue maternelle )</span>
                                            <span class="langue">Anglais ( technique )</span>
                                        </div>
                                    </div>
                                </div>
    
                        </div>
                    </div>
        
                </div>
            </div>
        </section>
        
        <section class="services" id="services">
            <svg id="wave-top" style="transform:rotate(180deg); transition: 0.3s" viewBox="0 0 1440 150" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(255, 255, 255, 1)" offset="0%"></stop><stop stop-color="rgba(255, 255, 255, 1)" offset="100%"></stop></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,105L18.5,100C36.9,95,74,85,111,90C147.7,95,185,115,222,107.5C258.5,100,295,65,332,62.5C369.2,60,406,90,443,102.5C480,115,517,110,554,100C590.8,90,628,75,665,62.5C701.5,50,738,40,775,50C812.3,60,849,90,886,107.5C923.1,125,960,130,997,130C1033.8,130,1071,125,1108,117.5C1144.6,110,1182,100,1218,100C1255.4,100,1292,110,1329,112.5C1366.2,115,1403,110,1440,102.5C1476.9,95,1514,85,1551,90C1587.7,95,1625,115,1662,120C1698.5,125,1735,115,1772,107.5C1809.2,100,1846,95,1883,87.5C1920,80,1957,70,1994,57.5C2030.8,45,2068,30,2105,42.5C2141.5,55,2178,95,2215,92.5C2252.3,90,2289,45,2326,42.5C2363.1,40,2400,80,2437,95C2473.8,110,2511,100,2548,92.5C2584.6,85,2622,80,2640,77.5L2658.5,75L2658.5,150L2640,150C2621.5,150,2585,150,2548,150C2510.8,150,2474,150,2437,150C2400,150,2363,150,2326,150C2289.2,150,2252,150,2215,150C2178.5,150,2142,150,2105,150C2067.7,150,2031,150,1994,150C1956.9,150,1920,150,1883,150C1846.2,150,1809,150,1772,150C1735.4,150,1698,150,1662,150C1624.6,150,1588,150,1551,150C1513.8,150,1477,150,1440,150C1403.1,150,1366,150,1329,150C1292.3,150,1255,150,1218,150C1181.5,150,1145,150,1108,150C1070.8,150,1034,150,997,150C960,150,923,150,886,150C849.2,150,812,150,775,150C738.5,150,702,150,665,150C627.7,150,591,150,554,150C516.9,150,480,150,443,150C406.2,150,369,150,332,150C295.4,150,258,150,222,150C184.6,150,148,150,111,150C73.8,150,37,150,18,150L0,150Z"></path></svg>
            <div class="content my-container">
                <span class="title">Services</span>
                
                <div class="intro-services">

                    <div class="intro">
                        <h3 >Pourquoi me confier votre projet ?</h3>
                        <span>Pour moi, le gage d'une mission réussie est l'implication active et continue  du client tout au long de la réalisation de son projet.</span>
                        <span>Je place le client au cœur du projet en lui offrant un suivi quotidien grâce à des réunions d'informations et de feedbacks réguliers, dans la mesure de sa disponibilité. Cela permet au client de ne pas avoir de mauvaises surprises à la fin du projet, mais plutôt une solution qui répond parfaitement à ses attentes.</span>
                        <span>Tout projet débutera par une phase de compréhension de votre besoin et une analyse de faisabilité pour s'assurer que nous sommes sur la même longueur d'onde et que nous avons une vision commune de ce que nous souhaitons réaliser ensemble.</span>
                    </div>

                    <div class="services-list">

                        <div class="service">
                            <fa-icon [icon]=faPalette></fa-icon>
                            <span class="service-title">Web Designer UI/UX</span>
                            <p>
                               Je crée des design personnalisés et spécifiques pour votre projet de création de site ou application web.
                            </p>
                        </div>
    
                        <div class="service" >
                            <fa-icon [icon]=faCode></fa-icon>
                            <span class="service-title">Création Site Web</span>
                            <p>
                                Je développe vos sites et applications web en optimisant les ressources ( temps de chargement, ... ) et en m'assurant de la bone qualité du code
                            </p>
                        </div>
    
                        <div class="service">
                            <fa-icon [icon]=faRocket></fa-icon>
                            <span class="service-title">Reférencement SEO</span>
                                <p>
                                    Devenez vraiment visible en améliorant votre positionnement sur Google auprès des internautes qui recherchent vos services chaque jour sur internet.
                                </p>
                        </div>
    
                        <div class="service">
                            <fa-icon [icon]=faChalkboardTeacher></fa-icon>
                            <span class="service-title">Formation</span>
                            <p>Vous êtes en recherche d'acquisition de compétences techniques dans le domaine du développement web?</p>
                            <p>Je vous apprends par la pratique à utiliser les technologies tels que ( HTML, CSS, Javascript, Angular, Nodejs, PostgreSQL, ...)</p>
                        </div>
                    </div>
                </div>
            </div>
            <svg id="wave-bottom" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 150" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgb(0, 1, 31)" offset="0%"></stop><stop stop-color="rgb(0, 1, 31)" offset="100%"></stop></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,105L18.5,100C36.9,95,74,85,111,90C147.7,95,185,115,222,107.5C258.5,100,295,65,332,62.5C369.2,60,406,90,443,102.5C480,115,517,110,554,100C590.8,90,628,75,665,62.5C701.5,50,738,40,775,50C812.3,60,849,90,886,107.5C923.1,125,960,130,997,130C1033.8,130,1071,125,1108,117.5C1144.6,110,1182,100,1218,100C1255.4,100,1292,110,1329,112.5C1366.2,115,1403,110,1440,102.5C1476.9,95,1514,85,1551,90C1587.7,95,1625,115,1662,120C1698.5,125,1735,115,1772,107.5C1809.2,100,1846,95,1883,87.5C1920,80,1957,70,1994,57.5C2030.8,45,2068,30,2105,42.5C2141.5,55,2178,95,2215,92.5C2252.3,90,2289,45,2326,42.5C2363.1,40,2400,80,2437,95C2473.8,110,2511,100,2548,92.5C2584.6,85,2622,80,2640,77.5L2658.5,75L2658.5,150L2640,150C2621.5,150,2585,150,2548,150C2510.8,150,2474,150,2437,150C2400,150,2363,150,2326,150C2289.2,150,2252,150,2215,150C2178.5,150,2142,150,2105,150C2067.7,150,2031,150,1994,150C1956.9,150,1920,150,1883,150C1846.2,150,1809,150,1772,150C1735.4,150,1698,150,1662,150C1624.6,150,1588,150,1551,150C1513.8,150,1477,150,1440,150C1403.1,150,1366,150,1329,150C1292.3,150,1255,150,1218,150C1181.5,150,1145,150,1108,150C1070.8,150,1034,150,997,150C960,150,923,150,886,150C849.2,150,812,150,775,150C738.5,150,702,150,665,150C627.7,150,591,150,554,150C516.9,150,480,150,443,150C406.2,150,369,150,332,150C295.4,150,258,150,222,150C184.6,150,148,150,111,150C73.8,150,37,150,18,150L0,150Z"></path></svg>
        </section>
        
        <section class="projects" id="projets">
            <div class="content my-container">
                <span class="title">Mes Réalisations</span>
                <span class="summary">Retrouvez ici quelques une de mes réalisations.</span>

                <div class="project-list">

                    <div class="project" (click)="showProject(project.projectCode)"  *ngFor="let project of projectList">
                        <div class="project-image">
                            <img src={{project.projectImage}} alt={{project.projectImageAltAttribute}} />
                        </div>
                        <span class="project-title">{{project.projectTitle}}</span>
                        <span class="project-summary">{{project.projectSummary}}</span>

                        <button>Voir plus</button>
                    </div>
                </div>
            </div>
        
        </section>
        
        <section class="contact" id="contact">
        
            <div class="content my-container">
                <span class="title">Contactez-moi</span>
                <span>Vous avez un projet ? Vous souhaitez obtenir des conseils, un devis gratuit ?</span>
                <span class="speach">Vous pouvez me contacter par le moyen qui vous sied le mieux parmis ceux indiqués ci-après .</span>        
                <div class="contact-info">
                    <div class="personal-id">
                        <div class="tel">
        
                            <div class="img-bloc">
                                <fa-icon [icon]="faPhone"></fa-icon>
                            </div>
        
                            <div class="text-bloc">
                                <span class="titles">Téléphone</span>
                                <span class="text">+33 783189171</span>
                            </div>
                            
                        </div>
            
                        <div class="mail">
                            <div class="img-bloc">
                                <fa-icon [icon]="faEnvelope"></fa-icon>
                            </div>
                            <div class="text-bloc">
                                <span class="titles">Email</span>
                                <span class="text">manduel21@gmail.com</span>
                            </div>
                        </div>
                    </div>
            
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">
                        <div class="name-email">
                            <div class="name-bloc">
                                <input formControlName="name" id="name-input" type="text" placeholder="Nom">
                                <label class="label-id"for="name">Nom</label>
                                <div *ngIf="isContactFormSubmitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Renseignez votre nom</div>
                                </div>
                            </div>
                            <div class="email-bloc">
                                <input formControlName="email" id="email-input" type="text" placeholder="Email">
                                <label class="label-id" for="email">Email</label>
                                <div *ngIf="isContactFormSubmitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Renseignez l'addresse mail</div>
                                    <div *ngIf="f.email.errors.email">Addresse mail invalide</div>
                                </div>
                            </div>
                        </div>
        
                        <div class="objet-bloc">
                            <input formControlName="subject" id="objet-input" type="text" placeholder="Objet">
                            <label class="label-id" for="objet-input">Objet</label>
                        </div>
        
                        <div class="message-bloc">
                            <textarea formControlName="message" id="message-area" type="text" placeholder="Message"></textarea>
                            <label class="label-id" for="message-area">Message</label>
                            <div *ngIf="isContactFormSubmitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Message manquant</div>
                                <div *ngIf="f.message.errors.minlength">Votre message est trop court </div>
                            </div>
                        </div>
        
                        <button type="submit" >
                            <ng-container *ngIf="isContactFormSubmittedAndNotErrorOnClientSide; else defaultText">
                                <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="8"></p-progressSpinner>
                            </ng-container>
                        </button>
                    </form>
                    <ng-template #defaultText>Envoyer <fa-icon [icon]="faPaperPlane"></fa-icon></ng-template>
                </div>
            </div>
            <p-toast [breakpoints]="{'920px': {width: '100%', right: '0', left: '0', backgroung:'red'}}"></p-toast>
        
        </section>

</div>

